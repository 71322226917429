@tailwind base;
@tailwind components;
@tailwind utilities;

.dark .gradient {
  background-image: linear-gradient(
    8deg,
    hsl(206deg 83% 40%) 0%,
    hsl(205deg 91% 35%) 13%,
    hsl(204deg 98% 30%) 24%,
    hsl(203deg 100% 26%) 33%,
    hsl(204deg 100% 23%) 40%,
    hsl(204deg 100% 20%) 47%,
    hsl(204deg 100% 17%) 55%,
    hsl(204deg 100% 14%) 64%,
    hsl(205deg 100% 11%) 78%,
    hsl(206deg 100% 8%) 100%
  );
}

.gradient {
  background-image: linear-gradient(
    10deg,
    hsl(207deg 100% 98%) 0%,
    hsl(207deg 96% 97%) 13%,
    hsl(207deg 94% 96%) 24%,
    hsl(207deg 92% 95%) 33%,
    hsl(207deg 91% 94%) 40%,
    hsl(207deg 90% 93%) 47%,
    hsl(207deg 90% 93%) 55%,
    hsl(206deg 89% 92%) 64%,
    hsl(206deg 89% 91%) 78%,
    hsl(206deg 88% 90%) 100%
  );
  background:white;
}

.drawn-animation {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: draw 2s linear forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}


.moving-gradients {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: fancyGradient 15s ease infinite;
}

@keyframes fancyGradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

